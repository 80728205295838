<template>
    <div class="auth">
        <div class="authIn">
            <el-result icon="success" title="扫码成功" subTitle="请稍等..."></el-result>
        </div>
    </div>
</template>

<script>
    export default {
        name: "auth",
        created() {
            console.log(location);
            if ('code' in this.$route.query) {
                window.parent.postMessage(this.$route.query, this.$route.query.state)
            }
        }
    }
</script>

<style scoped>
    .auth {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-color: #fff;
    }

    .authIn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
